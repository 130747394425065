// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_q6 d_fn d_bF d_dt";
export var alignLeft = "r_p5 d_fn d_bF d_dt";
export var alignDiscCenter = "r_q7 d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_q8 d_fq d_bG d_dw";
export var alignRight = "r_p6 d_fq d_bG d_dw";
export var footerContainer = "r_q9 d_dV d_bV";
export var footerContainerFull = "r_rb d_dS d_bV";
export var footerHeader = "r_kc d_kc";
export var footerTextWrapper = "r_rc d_v";
export var footerDisclaimerWrapper = "r_kk d_kk d_cj";
export var badgeWrapper = "r_rd d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_rf undefined";
export var footerDisclaimerLeft = "r_rg undefined";
export var verticalTop = "r_rh d_by d_bH d_bM d_bK";
export var firstWide = "r_rj";
export var disclaimer = "r_rk d_by d_bH";
export var socialDisclaimer = "r_rl";
export var left = "r_rm";
export var wide = "r_rn d_cx";
export var right = "r_rp d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_rq d_bB d_bN d_bH";
export var badgeContainer = "r_rr d_by d_bG d_bN";
export var badge = "r_rs";
export var padding = "r_rt d_c6";
export var end = "r_rv d_bG";
export var linkWrapper = "r_rw d_dz";
export var link = "r_mz d_dz";
export var colWrapper = "r_rx d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_ry d_bw d_dx";
export var itemRight = "r_rz";
export var itemLeft = "r_rB";
export var itemCenter = "r_rC";
export var exceptionWeight = "r_rD x_sQ";