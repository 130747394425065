// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rF d_gs d_cr";
export var heroHeaderCenter = "s_gt d_gt d_cr d_dv";
export var heroHeaderRight = "s_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "s_rG d_gp d_cv";
export var heroParagraphCenter = "s_gq d_gq d_cv d_dv";
export var heroParagraphRight = "s_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "s_rH d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_rJ d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_rK d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_rL d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_rM d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rN x_rN";
export var heroExceptionNormal = "s_rP x_rP";
export var heroExceptionLarge = "s_rQ x_rQ";
export var Title1Small = "s_rR x_rR x_sn x_sp";
export var Title1Normal = "s_rS x_rS x_sn x_sq";
export var Title1Large = "s_rT x_rT x_sn x_sr";
export var BodySmall = "s_rV x_rV x_sn x_sH";
export var BodyNormal = "s_rW x_rW x_sn x_sJ";
export var BodyLarge = "s_rX x_rX x_sn x_sK";