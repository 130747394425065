// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_t9";
export var lbContainerInner = "z_vb";
export var movingForwards = "z_vc";
export var movingForwardsOther = "z_vd";
export var movingBackwards = "z_vf";
export var movingBackwardsOther = "z_vg";
export var lbImage = "z_vh";
export var lbOtherImage = "z_vj";
export var prevButton = "z_vk";
export var nextButton = "z_vl";
export var closing = "z_vm";
export var appear = "z_vn";