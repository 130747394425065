// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "p_qm d_bC";
export var datasheetRowWrapper = "p_ls d_ls d_bJ";
export var datasheetLeftWrapper = "p_qn d_by d_bN";
export var datasheetWrapperFull = "p_qp d_cC";
export var datasheetWrapperFullLeft = "p_nF d_cC d_by d_bN d_bC d_bS";
export var contentWrapper = "p_px d_lt";
export var contentWrapperCenter = "p_qq d_ly";
export var datasheetLeftWrapperCenter = "p_qr d_lw d_by d_bC d_bN d_bH";
export var datasheetImageCenterWrapper = "p_lq d_lq d_v d_ct";
export var datasheetRightWrapperCenter = "p_qs d_lv";
export var datasheetFooter = "p_lx d_lx";
export var alignLeft = "p_p5 d_dt";
export var alignCenter = "p_bN d_dv";
export var alignRight = "p_p6 d_dw";
export var datasheetInnerWrapperNewLeft = "p_qt d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetInnerWrapperLeft = "p_qv d_fc d_N d_Y d_by d_bN d_b4 d_dh d_c8";
export var datasheetInnerWrapperRight = "p_qw d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetRowWrapperReverse = "p_qx d_bJ";
export var datasheetSubtitle = "p_qy d_cw";
export var tableRow = "p_qz";
export var cell = "p_qB";
export var tableRowWrapper = "p_qC d_v d_dt";
export var tableHeadingCell = "p_qD";
export var tableHeading = "p_qF";
export var tableRowStriped = "p_qG";
export var tableRowHeading = "p_qH";
export var dataTable = "p_qJ d_v";
export var imageWrapper = "p_qK d_ff d_Y";
export var imageWrapperFull = "p_qL d_G d_v d_bd d_Y";
export var imageWrapperIcon = "p_qM";
export var titleMargin = "p_qN d_cr";
export var datasheetInnerInnerWrapper = "p_qP d_v";
export var hasLabels = "p_qQ";
export var label = "p_qR";
export var SmallSmall = "p_qS x_qS x_sn x_sL";
export var SmallNormal = "p_qT x_qT x_sn x_sM";
export var SmallLarge = "p_qV x_qV x_sn x_sJ";